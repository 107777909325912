import {
	HomeOutlined,
	UserOutlined,
	UsergroupAddOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import {
	PermissaoGrupoUsuarioEnum,
	PermissaoUsuarioEnum,
	PermissaoGrupoSeguroEnum,
	PermissaoTipoVeiculoEnum,
	PermissaoEspecieVeiculoEnum,
	PermissaoComposicaoVeiculoEnum,
	PermissaoTipoSinistroEnum,
	PermissaoTipoDespesaSinistroEnum,
	PermissaoEstadoEnum,
	PermissaoCidadeEnum,
	PermissaoIntegranteEnum,
	PermissaoVistoriadorEnum,
	PermissaoSinistroEnum,
	PermissaoModeloVeiculoEnum,
	PermissaoMotoristaEnum,
	PermissaoPaisEnum,
	PermissaoVeiculoEnum,
	PermissaoFornecedorEnum,
	PermissaoDespesaSinistroEnum,
	PermissaoVendedorEnum,
	PermissaoPenalizacaoEnum,
} from "enum/permissao-enum";

const dashBoardNavTree = [
	{
		key: "dashboard",
		path: `${APP_PREFIX_PATH}/dashboard`,
		title: "dashboard",
		icon: HomeOutlined,
		breadcrumb: false,
		submenu: [],
	},
	{
		key: "lista-cadastros",
		path: `${APP_PREFIX_PATH}/lista-cadastros`,
		title: "lista-cadastros",
		icon: UserOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoVeiculoEnum.VISUALIZAR,
	},
	{
		key: "financeiro",
		path: `${APP_PREFIX_PATH}/financeiro`,
		title: "financeiro",
		icon: HomeOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoDespesaSinistroEnum.VISUALIZAR,
	},
	{
		key: "lista-integrante",
		path: `${APP_PREFIX_PATH}/lista-integrante`,
		title: "lista-integrante",
		icon: HomeOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoIntegranteEnum.VISUALIZAR,
	},
	{
		key: "lista-usuarios",
		path: `${APP_PREFIX_PATH}/lista-usuarios`,
		title: "lista-usuarios",
		icon: UserOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoUsuarioEnum.VISUALIZAR,
	},
	{
		key: "lista-grupos-usuario",
		path: `${APP_PREFIX_PATH}/lista-grupos-usuario`,
		title: "lista-grupos-usuario",
		icon: UsergroupAddOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoGrupoUsuarioEnum.VISUALIZAR,
	},
	{
		key: "lista-grupos-seguro",
		path: `${APP_PREFIX_PATH}/lista-grupos-seguro`,
		title: "lista-grupos-seguro",
		icon: HomeOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoGrupoSeguroEnum.VISUALIZAR,
	},
	{
		key: "lista-tipo-veiculo",
		path: `${APP_PREFIX_PATH}/lista-tipo-veiculo`,
		title: "lista-tipo-veiculo",
		icon: HomeOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoTipoVeiculoEnum.VISUALIZAR,
	},
	{
		key: "lista-especie-veiculo",
		path: `${APP_PREFIX_PATH}/lista-especie-veiculo`,
		title: "lista-especie-veiculo",
		icon: HomeOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoEspecieVeiculoEnum.VISUALIZAR,
	},
	{
		key: "lista-composicao-veiculo",
		path: `${APP_PREFIX_PATH}/lista-composicao-veiculo`,
		title: "lista-composicao-veiculo",
		icon: HomeOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoComposicaoVeiculoEnum.VISUALIZAR,
	},
	{
		key: "lista-tipo-sinistro",
		path: `${APP_PREFIX_PATH}/lista-tipo-sinistro`,
		title: "lista-tipo-sinistro",
		icon: HomeOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoTipoSinistroEnum.VISUALIZAR,
	},
	{
		key: "lista-tipo-despesa-sinistro",
		path: `${APP_PREFIX_PATH}/lista-tipo-despesa-sinistro`,
		title: "lista-tipo-despesa-sinistro",
		icon: HomeOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoTipoDespesaSinistroEnum.VISUALIZAR,
	},
	{
		key: "lista-sinistros",
		path: `${APP_PREFIX_PATH}/lista-sinistros`,
		title: "lista-sinistros",
		icon: HomeOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoSinistroEnum.VISUALIZAR,
	},
	{
		key: "lista-paises",
		path: `${APP_PREFIX_PATH}/lista-paises`,
		title: "lista-paises",
		icon: HomeOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoPaisEnum.VISUALIZAR,
	},
	{
		key: "lista-estados",
		path: `${APP_PREFIX_PATH}/lista-estados`,
		title: "lista-estados",
		icon: HomeOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoEstadoEnum.VISUALIZAR,
	},
	{
		key: "lista-cidades",
		path: `${APP_PREFIX_PATH}/lista-cidades`,
		title: "lista-cidades",
		icon: HomeOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoCidadeEnum.VISUALIZAR,
	},
	{
		key: "lista-vistoriador",
		path: `${APP_PREFIX_PATH}/lista-vistoriador`,
		title: "lista-vistoriador",
		icon: HomeOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoVistoriadorEnum.VISUALIZAR,
	},
	{
		key: "lista-modelo-veiculo",
		path: `${APP_PREFIX_PATH}/lista-modelo-veiculo`,
		title: "lista-modelo-veiculo",
		icon: HomeOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoModeloVeiculoEnum.VISUALIZAR,
	},
	{
		key: "lista-motoristas",
		path: `${APP_PREFIX_PATH}/lista-motoristas`,
		title: "lista-motoristas",
		icon: HomeOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoMotoristaEnum.VISUALIZAR,
	},
	{
		key: "lista-fornecedores",
		path: `${APP_PREFIX_PATH}/lista-fornecedores`,
		title: "lista-fornecedores",
		icon: HomeOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoFornecedorEnum.VISUALIZAR,
	},
	{
		key: "lista-vendedores",
		path: `${APP_PREFIX_PATH}/lista-vendedores`,
		title: "lista-vendedores",
		icon: HomeOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoVendedorEnum.VISUALIZAR,
	},
	{
		key: "lista-fipe",
		path: `${APP_PREFIX_PATH}/lista-fipe`,
		title: "lista-fipe",
		icon: HomeOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoVendedorEnum.VISUALIZAR,
	},
	{
		key: "lista-penalizacoes",
		path: `${APP_PREFIX_PATH}/lista-penalizacoes`,
		title: "lista-penalizacoes",
		icon: HomeOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoPenalizacaoEnum.VISUALIZAR,
	},
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
